import { KpiActionBase } from './kpi-action-base';
import { Router } from '@angular/router';
import { nav, notificationTopic } from '@app/shared/utils';
import { Injectable } from '@angular/core';
import { ReduceConsumptionModalComponent } from '@app/shared/modals';
import { DismountingPalletInformation } from 'chronos-shared';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { SecondaryMaterialDsService } from '@app/core/data-services';
import { finalize } from 'rxjs/operators';
import { SecondaryMaterialMounted } from 'chronos-core-client';
import { DismountDummyPalletModalComponent } from '@app/shared/components/dismount-dummy-pallet-modal/dismount-dummy-pallet-modal.component';

@Injectable({
  providedIn: 'root'
})
export class KpiActionConsumptionSecondaryLaminationService extends KpiActionBase {
  constructor(
    private router: Router,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private secondaryMaterialDsService: SecondaryMaterialDsService
  ) {
    super();
    this.loadingDismountTopic = notificationTopic.kpiConsumptionSecondaryDismount;
  }

  public execute(): void {
    this.router.navigate([nav.routes.mountingSecondary]);
  }

  public dismount(): void {
    this.publishLoadingDismountTopic(true);
    this.secondaryMaterialDsService
      .getNextContainerForDismount()
      .pipe(
        finalize(() => {
          this.publishLoadingDismountTopic(false);
        })
      )
      .subscribe((material) => {
        this.openDismountModal(material);
      });
  }

  private openDismountModal(material: SecondaryMaterialMounted): void {
    if (material?.isVirtualContainer) {
      this.openDummyPalletDismountingModal(material);
    } else {
      this.openPalletDismountingModal(material);
    }
  }

  private openPalletDismountingModal(material: SecondaryMaterialMounted): void {
    this.dialogService.open(ReduceConsumptionModalComponent, {
      header: this.translateService.instant('MOUNTING.DISMOUNT'),
      data: {
        article: material.article,
        containerId: material.containerId,
        bomUnitFactor: material.bomUnitFactor,
        inventoryUnitId: material.inventoryUnitId,
        reduceInformation: {
          mountedMaterialId: material.mountedMaterialId,
          mounted: material.mountedQuantity,
          consumed: material.consumedQuantity,
          initialQuantity: material.initialQuantity,
          ssccCode: material.ssccCode,
          mountedTime: new Date(material.mountedTime),
          mountedUser: material.mountedUser,
          printLabel: material.article.labelAutoPrint,
          mountedTo: material.mountedTo
        } as DismountingPalletInformation
      }
    });
  }

  public openDummyPalletDismountingModal(rowData: SecondaryMaterialMounted): void {
    this.dialogService.open(DismountDummyPalletModalComponent, {
      header: this.translateService.instant('DISMOUNT_DUMMY_PALLET_FORM.DISMOUNT_DUMMY'),
      data: {
        containerId: rowData.containerId,
        dismountingPalletInformation: {
          article: rowData.article,
          ssccCode: rowData.ssccCode,
          mounted: rowData.mountedQuantity,
          consumed: rowData.consumedQuantity,
          bomUnitFactor: rowData.bomUnitFactor,
          virtualContainerInfo: rowData.virtualContainerInfo,
          mountedMaterialId: rowData.mountedMaterialId,
          reason: rowData.virtualContainerReason
        } as DismountingPalletInformation
      }
    });
  }
}
