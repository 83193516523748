import { Component, OnInit } from '@angular/core';
import { MaterialTableDataRow, MaterialTableTreeNode } from '@app/modules/mounting/models/material-table.model';
import { TabTraceService } from '@app/modules/mounting/services/tab-trace/tab-trace.service';
import { TraceMaterialAssignationModalComponent } from '@app/modules/mounting/containers/tab-trace/components/trace-material-assignation-modal/trace-material-assignation-modal.component';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ButtonItem } from '@app/shared/components';
import { notificationTopic } from '@app/shared/utils';
import { LoadingNotificationService } from 'chronos-shared';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-tab-trace',
  templateUrl: './tab-trace.component.html',
  styleUrls: ['./tab-trace.component.scss']
})
export class TabTraceComponent implements OnInit {
  public readonly LOADING_TOPIC = notificationTopic.unassignTraceAction;
  public tableData: MaterialTableTreeNode[] = [];

  constructor(private tabTraceService: TabTraceService, private dialogService: DialogService, private translateService: TranslateService) {}

  public ngOnInit(): void {
    this.initTraceMaterials();
  }

  private initTraceMaterials(): void {
    this.tabTraceService.getTraceMaterial().subscribe((data) => (this.tableData = data));
  }

  public constructButtons(rowData: MaterialTableDataRow): ButtonItem[] {
    return [
      {
        label: 'MOUNTING.ASSIGN',
        command: () => {
          this.clickAssignButton(rowData);
        }
      }
    ];
  }

  private clickAssignButton(rowData: MaterialTableDataRow) {
    this.openNewTraceMaterialAssignationModal(rowData);
  }

  public unassignTraceMaterial(mountedMaterialId: number): void {
    const loadingTopic = this.LOADING_TOPIC + mountedMaterialId;

    LoadingNotificationService.publish(loadingTopic, true);

    this.tabTraceService
      .unassignTraceMaterial(mountedMaterialId)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(loadingTopic, false);
        })
      )
      .subscribe((data) => (this.tableData = data));
  }

  private openNewTraceMaterialAssignationModal(rowData: MaterialTableDataRow): void {
    this.dialogService
      .open(TraceMaterialAssignationModalComponent, {
        header: this.translateService.instant('MOUNTING.ASSIGN'),
        data: {
          materialBlockId: rowData.materialBlockId,
          article: rowData.article
        }
      })
      .onClose.subscribe((isSubmit: boolean) => {
        this.onCloseModal(isSubmit);
      });
  }

  private onCloseModal(isSubmit: boolean): void {
    if (isSubmit) {
      this.initTraceMaterials();
    }
  }
}
