<section class="active-order section-padding" *ngIf="activeOrderPanelData$ | async as activeOrderPanelData">
  <ng-container *ngIf="activeOrderPanelData.activeProductionOrder as activeOrder; else noActiveOrder">
    <div class="action-button">
      <button
        pButton
        (click)="onNavigateToActivePhaseClick()"
        class="action-button__back-to-active"
        [label]="'ACTIVE_ORDER.BACK_TO_CURRENT_PHASE' | translate"
      ></button>
    </div>
    <div class="active-order__heading">
      <div class="active-order--x-padding">
        <div class="section-header section-header--small">{{ 'ACTIVE_ORDER.TITLE' | translate }}</div>
        <div class="active-order__id">
          <i *ngIf="activeOrder.isCombinationOrder" class="pi pi-th-large active-order__combination-icon"></i>
          <div>{{ activeOrder.externalProductionOrderId }}</div>
        </div>
        <div class="sub-content">
          <span class="active-order__content">
            <div class="section-header section-header--small">{{ 'ACTIVE_ORDER.JOB_QUANTITY' | translate }}</div>
            <ng-container *ngIf="!activeOrder.isGoodQuantityPredecessorEnabled">
              <i class="pallet-amount__icon icon-pallet-4-4 active-order__content-icon"></i>
            </ng-container>
          </span>
          <div class="active-order__marker">{{ activeOrder.estimatedQuantity | formatQuantity }}</div>
          <ng-container *ngIf="activeOrderPanelData.showInactive">
            <div class="section-header section-header--small active-order__time">{{ 'ACTIVE_ORDER.TIME_LEFT' | translate }}</div>
            <div class="active-order__marker">{{ activeOrder.timeRemaining | elapsedTime }}</div>
          </ng-container>
          <div class="sub-content">
            <div class="section-header section-header--small">{{ activeOrder.customers[0]?.customerName }}</div>
            <div class="section-header section-header--small">{{ activeOrder.salesOrderIdAndPosition }}</div>
            <div class="section-header section-header--small">
              {{ activeOrder.finishedGoodArticles[0]?.externalArticleId }} / {{ activeOrder.finishedGoodArticles[0]?.configurationId }}
            </div>
          </div>
        </div>
      </div>
      <div class="active-order--x-padding">
        <ng-container *ngIf="activeOrderPanelData.enableMachineSpeedSimulation">
          <span *ngIf="isMachinePhaseVisible">
            <div class="active-order-current-phase__label">{{ 'ACTIVE_ORDER.MACHINE_PHASE' | translate }}</div>
            <div class="active-order__marker predecessor-value">{{ activeOrder.currentMachineRunPhaseType }}</div>
          </span>
        </ng-container>
        <div [ngClass]="activeOrderPanelData.enableMachineSpeedSimulation ? 'sub-content' : 'predecessor-content'">
          <div class="section-header section-header--small" [hidden]="isPredecesorStatusNone(activeOrder.predecessorStatus)">
            {{ 'ACTIVE_ORDER.STATUS_PREDECESSOR' | translate }}
          </div>
          <ng-container *ngIf="activeOrder.isPredecessorAvailable">
            <div
              [ngClass]="
                activeOrderPanelData.enableMachineSpeedSimulation ? 'active-order__marker' : 'active-order__marker predecessor-value'
              "
            >
              {{ STATUS_FLAGS[activeOrder.predecessorStatus].text | translate }}
            </div>
            <div [hidden]="isPredecessortQuantityAvailable(activeOrder.predecessorsGoodQuantity)">
              <div
                [ngClass]="
                  activeOrderPanelData.enableMachineSpeedSimulation ? 'active-order__content active-order__time' : 'active-order__content'
                "
              >
                <div class="section-header section-header--small">
                  {{ 'ACTIVE_ORDER.QUANTITY_PREDECESSOR' | translate }}
                </div>
                <ng-container *ngIf="activeOrder.isGoodQuantityPredecessorEnabled">
                  <i class="pallet-amount__icon icon-pallet-4-4 active-order__content-icon"></i>
                </ng-container>
              </div>
              <div class="active-order__marker">{{ activeOrder.predecessorsGoodQuantity | formatQuantity }}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="machineSimulator"></ng-container>
    <hr />
  </ng-container>
  <ng-template #noActiveOrder>
    <div class="section-header section-header--small">{{ 'ACTIVE_ORDER.TITLE' | translate }}</div>
    <div>
      <strong>—</strong>
    </div>
    <ng-container *ngTemplateOutlet="machineSimulator"></ng-container>
  </ng-template>

  <ng-template #machineSimulator>
    <div class="active-order--x-padding">
      <span *ngIf="showMachineSpeed()">
        <div class="section-header section-header--small active-order__time">{{ 'ACTIVE_ORDER.MACHINE_SPEED' | translate }}</div>
        <div class="section-header section-header--small">
          {{ 'ACTIVE_ORDER.CURRENT_SPEED' | translate : { speed: activeOrderPanelData.machineSpeed | number } }}
        </div>
        <lib-input-number
          [(ngModel)]="machineSpeedInput"
          (touched)="onMachineSpeedBlur()"
          (keyup.enter)="blurOnEnter($event)"
          [inputStyle]="{ width: '90px' }"
          [min]="0"
          [disabled]="isMachineSpeedDisabled"
        ></lib-input-number>
      </span>
      <div *ngIf="isClockInEnabled && !showMachineSpeed()" class="section-header section-header--small active-order__time">
        {{ 'ACTIVE_ORDER.CLOCKIN' | translate }}
      </div>
      <i *ngIf="isClockInEnabled" class="icon-clock active-order__date-icon cursor-clickable" (click)="openTimePickerModal()"></i>
    </div>
  </ng-template>

  <ng-container *ngIf="activeOrderPanelData.activeProductionOrder as activeProductionOrder">
    <lib-phase-navigation>
      <ng-container *ngFor="let phaseItem of phaseItems">
        <lib-phase-navigation-item
          [link]="phaseItem.link"
          [title]="phaseItem.title | translate"
          [icon]="phaseItem.icon"
          [isActive]="isPhaseActive(phaseItem.link)"
          [isPhaseActive]="isPhaseOfType(activeProductionOrder.runPhaseType, phaseItem.phaseType)"
          [hasSubItems]="phaseItem.subItems && phaseItem.subItems.length > 0"
        >
          <ng-container *ngFor="let subPhaseItem of phaseItem.subItems">
            <lib-sub-phase-navigation-item
              *ngIf="subPhaseItem.isActive"
              [link]="subPhaseItem.link"
              [title]="subPhaseItem.title | translate"
              [isActive]="isPhaseActive(subPhaseItem.link)"
              [isSubPhaseActive]="isSubPhaseOfType(activeProductionOrder.runSubPhaseType, subPhaseItem.subPhaseType)"
            ></lib-sub-phase-navigation-item>
          </ng-container>
        </lib-phase-navigation-item>
      </ng-container>
    </lib-phase-navigation>
  </ng-container>
</section>
