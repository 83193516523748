import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CheckProductionOrderEntry,
  MachineSchedule,
  PresetupProductionOrderEntry,
  ProductionOrderDeviationEntry,
  ProductionOrderProductionLoyalty,
  ProductionOrderService,
  ReopenProductionOrderEntry,
  UncheckProductionOrderEntry,
  CancelProductionOrderEntry,
  DefaultTargetContainerQuantityInfo,
  EditProductionOrderSetupPhaseEntry,
  Quantity,
  ProductionOrderSetupPhaseState,
  CancelEndProductionEntry
} from 'chronos-core-client';
import { ActiveOrderService, ProductionOrderListService } from '@app/core/global-state';
import { tap } from 'rxjs/operators';
import { MachineScheduleDsService } from '@app/core/data-services/machine-schedule-ds';
import { ShiftFilterType } from '@app/shared/models';
import { ActiveWorkCenterService } from '@app/core/workcenter';

@Injectable({
  providedIn: 'root'
})
export class ProductionOrderDsService {
  constructor(
    private productionOrderService: ProductionOrderService,
    private machineScheduleDsService: MachineScheduleDsService,
    private productionOrderListService: ProductionOrderListService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private activeOrderService: ActiveOrderService
  ) {}

  public setCheckedProductionOrder(productionOrderId: number, isChecked: boolean): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    if (isChecked) {
      const entry: CheckProductionOrderEntry = {
        workCenterId
      };
      const params: ProductionOrderService.CheckProductionOrderParams = {
        entry,
        productionOrderId
      };
      return this.productionOrderService.checkProductionOrder(params).pipe(
        tap(() => {
          this.productionOrderListService.setCheckedProductionOrder(productionOrderId);
        })
      );
    } else {
      const entry: UncheckProductionOrderEntry = {
        workCenterId
      };
      const params: ProductionOrderService.UncheckProductionOrderParams = {
        entry,
        productionOrderId
      };
      return this.productionOrderService.uncheckProductionOrder(params).pipe(
        tap(() => {
          this.productionOrderListService.setUncheckedProductionOrder(productionOrderId);
        })
      );
    }
  }

  public presetupProductionOrder(productionOrderId: number): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: PresetupProductionOrderEntry = {
      workCenterId
    };
    const params: ProductionOrderService.PresetupProductionOrderParams = {
      entry,
      productionOrderId
    };
    return this.productionOrderService.presetupProductionOrder(params);
  }

  public getProductionLoyaltyReasons(productionOrderId: number): Observable<ProductionOrderProductionLoyalty> {
    return this.productionOrderService.getProductionLoyaltyReasons(productionOrderId);
  }

  public setAsNextOrder(productionOrderId: number, entry: ProductionOrderDeviationEntry): Observable<null> {
    const params: ProductionOrderService.SetAsNextProductionOrderParams = {
      productionOrderId,
      entry
    };
    return this.productionOrderService.setAsNextProductionOrder(params);
  }

  public getProductionOrderListByShift(
    showFinished: boolean,
    shiftFilterType?: ShiftFilterType,
    calculateSecondaryMachineLocationKpi?: boolean
  ): Observable<MachineSchedule[]> {
    this.productionOrderListService.setLoading(true);
    return this.machineScheduleDsService
      .filterMachineScheduleList(showFinished, shiftFilterType, calculateSecondaryMachineLocationKpi)
      .pipe(
        tap((productionOrders) => {
          this.productionOrderListService.setProductionOrders(productionOrders);
        })
      );
  }

  public reopenProductionOrder(productionOrderId: number): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    const entry: ReopenProductionOrderEntry = {
      workCenterId
    };
    const params: ProductionOrderService.ReopenProductionOrderParams = {
      productionOrderId,
      entry
    };
    return this.productionOrderService.reopenProductionOrder(params).pipe(
      tap(() => {
        this.productionOrderListService.reopenProductionOrder(productionOrderId);
      })
    );
  }

  public resetProductionOrders(): Observable<null> {
    return this.productionOrderService.resetProductionOrders();
  }

  public cancelProductionOrder(productionOrderId: number): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    const entry: CancelProductionOrderEntry = {
      workCenterId
    };
    const params: ProductionOrderService.CancelProductionOrderParams = {
      productionOrderId,
      entry
    };
    return this.productionOrderService.cancelProductionOrder(params).pipe(
      tap(() => {
        this.removeActiveOrder();
      })
    );
  }

  public removeActiveOrder(): void {
    this.activeOrderService.reset();
  }

  public getDefaultTargetContainerQuantityInfo(productionOrderId: number): Observable<DefaultTargetContainerQuantityInfo> {
    return this.productionOrderService.getDefaultTargetContainerQuantity(productionOrderId);
  }

  public editProductionOrderSetupPhase(
    setupStartTime: string,
    setupEndTime: string,
    setupQuantity: Quantity,
    setupStartCounter: number,
    setupEndCounter: number,
    productionOrderId: number
  ): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    const editEndTimeEntry: EditProductionOrderSetupPhaseEntry = {
      workCenterId,
      setupEndTime,
      setupStartTime,
      setupStartCounter,
      setupEndCounter,
      setupQuantity
    };

    const params: ProductionOrderService.EditProductionOrderSetupPhaseParams = {
      productionOrderId,
      entry: editEndTimeEntry
    };

    return this.productionOrderService.editProductionOrderSetupPhase(params);
  }

  public getSetupPhaseState(
    productionOrderId: number,
    runId: number,
    calledFromApproveReport: boolean
  ): Observable<ProductionOrderSetupPhaseState> {
    const param: ProductionOrderService.GetSetupPhaseStateParams = {
      productionOrderId,
      runId,
      calledFromApproveReport
    };
    return this.productionOrderService.getSetupPhaseState(param);
  }

  public cancelEndProduction(productionOrderId: number): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    const cancelEndProductionEntry: CancelEndProductionEntry = {
      workCenterId
    };

    const param: ProductionOrderService.CancelEndProductionParams = {
      productionOrderId,
      entry: cancelEndProductionEntry
    };

    return this.productionOrderService.cancelEndProduction(param);
  }
}
